import './css/ourservices.css';

function OurServices(){
    const phonenumber = "+97143312236";
    const whatsapp = "https://wa.me/+971503210099";


    return(
        <>
            <section id="services" className='our-services'>
                <div className='container-fluid'>
                    <div className='row'>
                        <div className='col-12'>
                            <div className='section-header'>
                                <h2> Choose Dar Alkhobra Technology </h2>
                                <h1> <span> Reliable </span>, <span> efficient </span>, and <span> comprehensive </span> electronic services </h1>
                            </div>
                        </div>


                        <div className='col-lg-4 col-md-6 col-sm-12 col-12'>
                            <div className='service'>
                                <span> 01 </span>
                                <i class="las la-laptop"></i>

                                <h1> <span> </span>  Laptop Sales </h1>
                                <p> 
                                We offer a wide range of high-quality laptops, both new and used, to cater to various needs and budgets. Whether you’re looking for a high-performance gaming laptop or a reliable device for work or study, we have you covered. Over 500 models available.
                                </p>

                                <a  href={`${whatsapp}`} target='_blank' aria-label='contact us'> Order Now</a>
                            </div>
                        </div>


                        
                        <div className='col-lg-4 col-md-6 col-sm-12 col-12'>
                            <div className='service'>
                                <span> 02 </span>
                                <i class="las la-mouse"></i>

                                <h1> <span> </span>  Computer Accessories </h1>
                                <p> 
                                Enhance your computing experience with our extensive selection of computer accessories. From keyboards and mice to external storage and cooling pads, we provide everything you need to optimize your setup. More than 300 accessories in stock.
                                </p>

                                <a  href={`${whatsapp}`}  target='_blank' aria-label='contact us'> Order Now</a>
                            </div>
                        </div>


                        
                        <div className='col-lg-4 col-md-6 col-sm-12 col-12'>
                            <div className='service'>
                                <span> 03 </span>
                                <i class="las la-user-friends"></i>

                                <h1> <span> </span>  Wholesale and Retail Sales </h1>
                                <p> 
                                Whether you’re a reseller or a consumer, we offer both wholesale and retail options to suit your purchasing needs. Enjoy competitive pricing and bulk discounts on a variety of products. Up to 20% discount on bulk orders.
                                </p>

                                <a  href={`${whatsapp}`}  target='_blank' aria-label='contact us'> Order Now</a>
                            </div>
                        </div>


                        
                        <div className='col-lg-4 col-md-6 col-sm-12 col-12'>
                            <div className='service'>
                                <span> 04 </span>
                                <i class="las la-tools"></i>

                                <h1> <span> </span>  Electronic Maintenance Services </h1>
                                <p> 
                                Our expert technicians are ready to assist with all your electronic maintenance needs. From routine check-ups to complex repairs, we ensure your devices remain in top condition. 90% of repairs completed within 24 hours.
                                </p>

                                <a  href={`${whatsapp}`}  target='_blank' aria-label='contact us'> Order Now</a>
                            </div>
                        </div>


                        
                        <div className='col-lg-4 col-md-6 col-sm-12 col-12'>
                            <div className='service'>
                                <span> 05 </span>
                                <i class="las la-desktop"></i>

                                <h1> <span> </span>  Custom PC Builds </h1>
                                <p> 
                                Create your dream PC with our custom build services. Choose from a range of components and let our experts assemble a machine tailored to your specific requirements and preferences. Over 100 custom builds completed annually.
                                </p>

                                <a  href={`${whatsapp}`}  target='_blank' aria-label='contact us'> Order Now</a>
                            </div>
                        </div>


                        
                        <div className='col-lg-4 col-md-6 col-sm-12 col-12'>
                            <div className='service'>
                                <span> 06 </span>
                                <i class="las la-user-astronaut"></i>

                                <h1> <span> </span>  Tech Consultation </h1>
                                <p> 
                                Need help deciding on the right products or services? Our knowledgeable team offers personalized tech consultations to help you make informed decisions. 98% customer satisfaction rate.


                                </p>

                                <a  href={`${whatsapp}`}  target='_blank' aria-label='contact us'> Order Now</a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default OurServices;