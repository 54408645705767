import './css/whychooseus.css';


function WhyChooseUs(){

    const phonenumber = "+97143312236";
    const whatsapp = "https://wa.me/+971503210099";


    return(
        <>
            <section id="why-choose-us" className='why-choose-us'>
                <div className='container-fluid'>
                    <div className='row'>
                        
                        <div className='col-lg-6 col-md-6 col-sm-12 col-12'>
                            <div className='text'>
                                <h2> WHY CHOOSE US ? </h2>
                                <h1> Choose <span> Dar Alkhobra </span> Technology </h1>
                                <p> 
                                We are committed to providing top-notch products, exceptional service, and unbeatable value. Thank you for trusting us with your electronic needs.
                                </p>

                                <a href={`${whatsapp}`} target='_blank' aria-label='send message'> 
                                    Make Your Order Now <i class="las la-arrow-right"></i>
                                </a>
                            </div>
                        </div>

                        <div className='col-lg-6 col-md-6 col-sm-12 col-12'>
                            <div className='row'>
                                <div className='col-lg-6 col-md-6 col-sm-12 col-12'>
                                    <div className='reason'>
                                        <div className='top'>
                                        <i class="las la-star"></i>
                                            <div>
                                                <h2> Quality Assurance </h2>
                                                <h1>  98 % </h1>
                                            </div>
                                        </div>

                                        <p>
                                        Every product in our inventory is sourced from reputable manufacturers and undergoes rigorous quality checks
                                        </p>
                                    </div>
                                </div>


                                <div className='col-lg-6 col-md-6 col-sm-12 col-12'>
                                    <div className='reason'>
                                        <div className='top'>
                                        <i class="las la-users"></i>
                                            <div>
                                                <h2> Customer-Centric Approach </h2>
                                                <h1> 95 %</h1>
                                            </div>
                                        </div>

                                        <p>
                                        Our knowledgeable and friendly staff are always on hand to provide personalized recommendations and expert support.
                                        </p>
                                    </div>
                                </div>


                                <div className='col-lg-6 col-md-6 col-sm-12 col-12'>
                                    <div className='reason'>
                                        <div className='top'>
                                            <i class="las la-money-bill-alt"></i>
                                            <div>
                                                <h2> Competitive Pricing </h2>
                                                <h1> 90 % </h1>
                                            </div>
                                        </div>

                                        <p>
                                        We understand the value of your investment, which is why we offer some of the most competitive prices in the market
                                        </p>
                                    </div>
                                </div>


                                <div className='col-lg-6 col-md-6 col-sm-12 col-12'>
                                    <div className='reason'>
                                        <div className='top'>
                                            <i class="las la-tasks"></i>
                                            <div>
                                                <h2> Extensive Selection </h2>
                                                <h1> 1,000  +</h1>
                                            </div>
                                        </div>

                                        <p>
                                        From high-performance laptops and essential computer accessories to expert maintenance services, we have everything you need under one roof
                                        </p>
                                    </div>
                                </div>

                            </div>
                        </div>


                    </div>
                </div>
            </section>
        </>
    )
}

export default WhyChooseUs;