/* global google */

import './css/navbar.css';
import Logo from './images/logo.webp';
import React, { useState, useEffect } from "react";
import $ from 'jquery';

function Navbar() {
    const [translationInitiated, setTranslationInitiated] = useState(false);
    const phonenumber = "+97143312236";
    const whatsapp = "https://wa.me/+971503210099";
    const email = "info@darakt.com";

    // Function to handle the "A" element click
    function handleTranslationClick() {
        if (!translationInitiated) {
            // Initialize the Google Translate widget with Arabic as the default language
            new google.translate.TranslateElement(
                { pageLanguage: 'en', includedLanguages: 'ar', autoDisplay: true },
                'google_translate_element'
            );

            // Hide the translation widget's powered by Google branding (optional)
            const branding = document.querySelector('.goog-logo-link');
            if (branding) {
                branding.style.display = 'none';
            }

            // Set the state variable to true to indicate that translation has been initiated
            setTranslationInitiated(true);
        }
    }

    useEffect(() => {
        $("#open-nav").on("click", function() {
            $(".nav-elements").slideDown(400);
            $("nav").addClass("active");
            $(".nav-elements").css({ display: "flex" });
        });

        $("#close-nav").on("click", function() {
            $(".nav-elements").slideUp(400);
            $("nav").removeClass("active");
        });

        if ($(window).width() < 950) {
            $(".nav-elements ul li").on("click", function() {
                $(".nav-elements").slideUp(400);
                $("nav").removeClass("active");
            });
        }

        const loadingElement = document.getElementById("loading");
        if (loadingElement) {
            loadingElement.remove();
        }

        // Handle smooth scrolling for nav links
        $(".nav-elements a").on("click", function(event) {
            event.preventDefault();
            const targetId = $(this).attr("href").substring(1);
            const targetElement = document.getElementById(targetId);
            if (targetElement) {
                $('html, body').animate({
                    scrollTop: $(targetElement).offset().top
                }, 800);
            }
        });
    }, []);

    return (
        <>
            <div id="google_translate_element"></div>

            <nav>
                <div className='container-fluid'>
                    <div className='upper-nav'>
                        <div className='content'>
                            <div className='left'>
                                <a href="#" onClick={handleTranslationClick}>
                                    AR
                                </a>
                                <a href={`tel:${phonenumber}`} aria-label='call us'>
                                    Give us a call
                                </a>
                                <span></span>
                                <a href={`${whatsapp}`} target='_blank' aria-label='send message'>
                                    Send message
                                </a>
                                <span></span>
                                <a href='#' aria-label='offers'>
                                    Offers?
                                </a>
                            </div>
                            <div className='right'>
                                <a href={`tel:${phonenumber}`} aria-label='call us'>
                                    <i className="las la-phone-volume"></i>
                                    +971 4 331 2236
                                </a>
                                <span></span>
                                <a href={`mailto:${email}`} aria-label='call us'>
                                    <i className="lar la-envelope"></i>
                                    {email}
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className='lower-nav'>
                        <div className='content'>
                            <div className='logo'>
                                <img src={Logo} alt='logo' />
                            </div>
                            <button id="open-nav"> <i className="las la-bars"></i> </button>
                            <div className="nav-elements">
                                <button id="close-nav"> <i className="las la-times"></i> </button>
                                <ul>
                                    <li>
                                        <a className='active' href='#home' aria-label='home section link'>
                                            HOME
                                        </a>
                                    </li>
                                    <li>
                                        <a href='#about' aria-label='about us section link'>
                                            ABOUT US
                                        </a>
                                    </li>
                                    <li>
                                        <a href='#services' aria-label='services section link'>
                                            SERVICES
                                        </a>
                                    </li>
                                    <li>
                                        <a href='#benefits' aria-label='benefits section link'>
                                            BENEFITS
                                        </a>
                                    </li>
                                    <li>
                                        <a href='#faqs' aria-label='faqs section link'>
                                            FAQS
                                        </a>
                                    </li>
                                    <li>
                                        <a href='#contact' aria-label='contact us section link'>
                                            CONTACT US
                                        </a>
                                    </li>
                                </ul>
                            </div>
                            <div className='right'>
                                <a href={`tel:${phonenumber}`} aria-label='call us'>
                                    <i className="las la-phone-volume"></i>
                                </a>
                                <a href='#' aria-label='about us page'>
                                    More About Us?
                                </a>
                                <a href={`${whatsapp}`} target='_blank' aria-label='whatsapp us'>
                                    <i className="lab la-whatsapp"></i>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </nav>
        </>
    );
}

export default Navbar;
