import './css/stats.css';

import StateImg1 from './images/state1.webp';
import StateImg2 from './images/state2.webp';



function Stats(){

    const phonenumber = "+97143312236";
    const whatsapp = "https://wa.me/+971503210099";

    return(
        <>
            <section id="benefits" className='stats'>
                <div className='container-fluid'>
                    <div className='row'>
                        <div className='col-lg-6 col-md-6 col-sm-12 col-12'>
                            <div className='text'>
                                <h2> 
                                Our stats speak for themselves
                                </h2>
                                <h1>
                                Ensuring you receive the best products and  
                                    <span>
                                    services
                                    </span>
                                    in the industry.
                                </h1>

                                <p>
                                Discover why Dar Alkhobra Technology is a trusted name in the electronics industry. Our commitment to quality, customer satisfaction, and continuous innovation is reflected in our impressive statistics:
                                </p>

                                <div className='stats-bars'>

                                    <div className='stat-bar'>
                                        <h1>
                                            Quality Products
                                        </h1>

                                        <div className='bar'>
                                            <div className='bar-content'>
                                                <span>
                                                    98% 
                                                </span>
                                            </div>
                                        </div>


                                    </div>


                                    <div className='stat-bar'>
                                        <h1>
                                            Customer Satisfaction
                                        </h1>

                                        <div className='bar'>
                                            <div className='bar-content'>
                                                <span>
                                                95%
                                                </span>
                                            </div>
                                        </div>


                                    </div>



                                    <div className='stat-bar'>
                                        <h1>
                                        Competitive Pricing
                                        </h1>

                                        <div className='bar'>
                                            <div className='bar-content'>
                                                <span>
                                                    90%
                                                </span>
                                            </div>
                                        </div>


                                    </div>


                                </div>


                                <a href='about' aria-label='discover more'> Discover More </a>
                            </div>
                        </div>


                        <div className='col-lg-6 col-md-6 col-sm-12 col-12'>
                            <div className='images'>
                                <img src={StateImg1} alt="state image"/>
                                <img src={StateImg2} alt="state image"/>
                                <a href={`tel:${phonenumber}`} alt="Contact us"><i class="las la-headset"></i></a>
                            </div>
                        </div>

                    </div>
                </div>
            </section>
        </>
    )
}
export default Stats;