import './css/about.css';

import AboutImg from './images/aboutimage.webp';


function About(){
    return(
        <>
            <section id="about" className='about'>
                <div className='container-fluid'>
                    <div className='row'>
                        
                        <div className='col-lg-6 col-md-6 col-sm-12 col-12'>
                            <div className='text'>
                                <h2> Dar Alkhobra Technology is dedicated to helping you </h2>

                                <h1> Your trusted partner in the world of <span> cutting-edge </span> electronics </h1>

                                <p>
                                Over the years, Dar Alkhobra Technology has grown from a small startup to a leading name in the electronics market. Our journey is marked by a relentless pursuit of innovation, customer satisfaction, and a deep understanding of the evolving technological landscape.
                                </p>

                                <div className='about-benefits'>

                                    <div className='about-benefit'>
                                        <i class="las la-award"></i>
                                        <div>
                                            <h3> 
                                                Quality Assurance
                                            </h3>
                                            <p>
                                            We source our products from reputable manufacturers, ensuring that every item in our inventory meets stringent quality standards
                                            </p>
                                        </div>
                                    </div>

                                    <div className='about-benefit'>
                                        <i class="las la-user-check"></i>
                                        <div>
                                            <h3> 
                                            Customer-Centric Approach
                                            </h3>
                                            <p>
                                            Our knowledgeable and friendly staff are always ready to assist you, providing personalized recommendations and support.
                                            </p>
                                        </div>
                                    </div>


                                    <div className='about-benefit'>
                                        <i class="las la-hand-holding-usd"></i>
                                        <div>
                                            <h3> 
                                            Competitive Pricing
                                            </h3>
                                            <p>
                                            We offer some of the best prices in the market, making high-quality technology affordable for all.
                                            </p>
                                        </div>
                                    </div>



                                    <div className='about-benefit'>
                                        <i class="las la-check-square"></i>
                                        <div>
                                            <h3> 
                                            Extensive Selection
                                            </h3>
                                            <p>
                                            Whether you need a high-performance laptop, essential computer accessories, or expert maintenance services, we have it all.
                                            </p>
                                        </div>
                                    </div>


                                </div>


                                <a href='#services' aria-label="service section link"> Discover More</a>
                            </div>
                        </div>



                        <div className='col-lg-6 col-md-6 col-sm-12 col-12'>
                            <div className='image'>
                                <div className='overlay'></div>
                                <img src={AboutImg} alt="about image"/>
                            </div>
                        </div>


                    </div>
                </div>
            </section>
        </>
    )
}


export default About;