import './css/footer.css';
import Logo from './images/logo.webp';

function Footer(){
    return(
        <>
            <footer>
                <div className='container-fluid'>
                    <div className='row'>
                        <div className='col-lg-4 col-md-4 col-sm-6 col-12'>
                            <div className='footer-content'>
                                <div className='logo'>
                                    <img src={Logo} alt='logo' />
                                </div>

                                <p>
                                Our knowledgeable partners and staff are always ready to assist you in finding the perfect device or accessory to enhance your digital experience. Whether you’re a student, a seller, or involved in electronic maintenance, Dar Alkhobra Technology is dedicated to helping you make the right choices.
                                </p>

                                <div className='input'> 
                                    <input type='email' placeholder='Your Email' />
                                    <button type='submit' > <i class="las la-paper-plane"></i> </button>
                                </div>

                                <h2> Subscribe to our newsletter to stay updated on the latest products and offers.</h2>
                            </div>
                        </div>

                        <div className='col-lg-2 col-md-2 col-sm-6 col-12'>
                            <div className='footer-content'>
                                <h1> 
                                Quick Links
                                </h1>

                                <ul>
                                    <li>
                                        <a href='#' aria-label='home section link'>
                                            Home
                                        </a>
                                    </li>

                                    <li>
                                        <a href='#' aria-label='About Us section link'>
                                        About Us
                                        </a>
                                    </li>
                                    <li>
                                        <a href='#' aria-label='Products section link'>
                                        Products
                                        </a>
                                    </li>

                                    <li>
                                        <a href='#' aria-label='FAQs section link'>
                                        FAQs
                                        </a>
                                    </li>


                                    <li>
                                        <a href='#' aria-label='Contact Us section link'>
                                        Contact Us
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>


                        <div className='col-lg-2 col-md-2 col-sm-6 col-12'>
                            <div className='footer-content'>
                                <h1> 
                                Our Services
                                </h1>

                                <ul>
                                    <li>
                                        <a href='#' aria-label='Services section link'>
                                        Laptop Sales
                                        </a>
                                    </li>
                                    <li>
                                        <a href='#' aria-label='Services section link'>
                                        Computer Accessories
                                        </a>
                                    </li>

                                    <li>
                                        <a href='#' aria-label='Services section link'>
                                        Wholesale and Retail Sales
                                        </a>
                                    </li>


                                    <li>
                                        <a href='#' aria-label='Services section link'>
                                        Electronic Maintenance Services
                                        </a>
                                    </li>

                                    <li>
                                        <a href='#' aria-label='Services section link'>
                                        Custom PC Builds
                                        </a>
                                    </li>


                                    <li>
                                        <a href='#' aria-label='Services section link'>
                                        Tech Consultation
                                        </a>
                                    </li>

                                    <li>
                                        <a href='#' aria-label='Services section link'>
                                        Software Solutions
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>



                        <div className='col-lg-2 col-md-2 col-sm-6 col-12'>
                            <div className='footer-content'>
                                <h1> 
                                    Our Values
                                </h1>

                                <ul>
                                    <li>
                                        <a href='#'  aria-label='Benefits section link'>
                                        Quality
                                        </a>
                                    </li>

                                    <li>
                                        <a href='#'  aria-label='Benefits section link'>
                                        Customer Satisfaction
                                        </a>
                                    </li>


                                    <li>
                                        <a href='#'  aria-label='Benefits section link'>
                                        Innovation
                                        </a>
                                    </li>

                                    <li>
                                        <a href='#'  aria-label='Benefits section link'>
                                        Integrity
                                        </a>
                                    </li>


                                    <li>
                                        <a href='#'  aria-label='Benefits section link'>
                                        Reliability
                                        </a>
                                    </li>


                                    <li>
                                        <a href='#'  aria-label='Benefits section link'>
                                        Excellence
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>


                        <div className='col-lg-2 col-md-2 col-sm-6 col-12'>
                            <div className='footer-content'>
                                <h1> 
                                About Us
                                </h1>

                                <p>
                                Dar Alkhobra Technology is your trusted source for cutting-edge electronics and accessories. Established in 2001, we are committed to providing high-quality products and exceptional customer service. Learn more about our journey and values.
                                </p>
                            </div>
                        </div>


                    </div>

                    <div className='copy-right'>
                        <h5>  &copy; 2024 Dar Alkhobra Technology. All Rights Reserved</h5>

                        <div className='socials'>
                            <a href='#' aria-label='facebook link'>
                                <i class="lab la-facebook-f"></i>
                            </a>

                            <a href='#' aria-label='twitter link'>
                                <i class="lab la-twitter"></i>
                            </a>

                            <a href='#' aria-label='linkedin link'>
                                <i class="lab la-linkedin-in"></i>
                            </a>

                            <a href='#' aria-label='instagram link'>
                                <i class="lab la-instagram"></i>
                            </a>
                        </div>
                    </div>
                </div>


            </footer>
        </>
    )
}

export default Footer;