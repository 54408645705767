import './css/landing.css';

import LandingImg1 from './images/landing1.webp';
import LandingImg2 from './images/landing2.webp';
import LandingImg3 from './images/landing3.webp';


function Landing(){

    const phonenumber = "+97143312236";
    const whatsapp = "https://wa.me/+971503210099";


    return(
        <>
            <section id="home" className='landing'>
                <div className='container-fluid'>
                    <div className='row'>
                        <div className='col-lg-5 col-md-5 col-sm-12 col-12'>
                            <div className='text'>
                                <h2> 
                                    Welcome to Dar Alkhobra Technology
                                </h2>

                                <h1> 
                                Innovative  Electronics, <span> Unmatched Quality </span>
                                </h1>

                                <p>
                                Your one-stop company for cutting-edge electronics and accessories. Established in 2001, we pride ourselves on offering a wide range of high-quality new and used laptops, along with an array of computer accessories. Whether you’re looking for wholesale or retail sales, Dar Alkhobra Technology is your go-to destination for superior electronic products.
                                </p>

                                <div>
                                    <a  href={`${whatsapp}`} target='_blank' aria-label='contact us'>  
                                        Order Now
                                    </a>

                                    <a href='#services'>
                                        View Services 
                                        <i class="las la-arrow-right"></i>
                                    </a>
                                </div>
                            </div>
                        </div>


                        <div className='col-lg-7 col-md-7 col-sm-12 col-12'>
                            <div className='images'>

                                <div>
                                    <div className='overlay'></div>
                                    <img src={LandingImg1}  alt="landing image"/>
                                    <a href="#benefits" aria-label='benefits section link'> 
                                        What Makes You Trust Us ? 
                                        <i class="las la-arrow-right"></i>
                                    </a>
                                </div>

                                <div>
                                    <div className='overlay'></div>
                                    <img src={LandingImg2}  alt="landing image"/>
                                    <a href="#services" aria-label='services section link'> 
                                        Discover Our Services 
                                        <i class="las la-arrow-right"></i>
                                    </a>
                                </div>

                            </div>
                        </div>

                        <div className='col-lg-12 col-md-12 col-sm-12 col-12'>
                            <div className='image'>
                                <div className='overlay'></div>
                                <img src={LandingImg3}  alt="landing image" />
                                <a href="#faqs" aria-label='faqs section link'> 
                                    Do You Have Questions ?  
                                    <i class="las la-arrow-right"></i>
                                </a>

                            </div>
                        </div>


                    </div>
                </div>
            </section>
        </>
    )
}

export default Landing;