import React, { useState } from 'react';
import './css/faqs.css';

function Faqs() {
    const phonenumber = "+97143312236";
    const whatsapp = "https://wa.me/+971503210099";

    const [activeQuestion, setActiveQuestion] = useState(null);

    const toggleActive = (index) => {
        setActiveQuestion(activeQuestion === index ? null : index);
    };

    return (
        <>
            <section id="faqs" className='faqs'>
                <div className='container-fluid'>
                    <div className='faqs-content'>
                        <div className='header'>
                            <h2>
                                OUR EXPERTS ANSWER
                            </h2>
                            <h1>
                                Frequently Asked <span> Questions (FAQs) </span>
                            </h1>
                            <p>
                                If you have any other questions or need further assistance, please don't hesitate to reach out to us. We are here to help!
                            </p>
                        </div>

                        <div className='questions'>
                            {[
                                {
                                    question: "What types of products do you offer?",
                                    answer: "We offer a wide range of products, including new and used laptops, computer accessories, and various electronic devices. Our inventory is continuously updated with the latest models to ensure you have access to the best technology."
                                },
                                {
                                    question: "Do you provide wholesale options?",
                                    answer: "Yes, we offer both wholesale and retail sales. Whether you're a reseller or an individual customer, we have pricing options to suit your needs, including bulk discounts for large orders."
                                },
                                {
                                    question: "How do I place an order?",
                                    answer: "You can place an order through our website, over the phone, or by visiting our store. Our customer service team is always ready to assist you with your purchase."
                                },
                                {
                                    question: "What is your return policy?",
                                    answer: "We have a flexible return policy to ensure customer satisfaction. If you are not satisfied with your purchase, you can return the product within 30 days for a full refund or exchange, provided it is in its original condition and packaging."
                                },
                                {
                                    question: "Do you offer repair and maintenance services?",
                                    answer: "Yes, we offer comprehensive electronic maintenance services. Our expert technicians can handle everything from routine check-ups to complex repairs. Most repairs are completed within 24 hours."
                                }
                            ].map((item, index) => (
                                <div className='question' key={index}>
                                    <h1 onClick={() => toggleActive(index)}>
                                        <i className="las la-arrow-down"></i>
                                        {item.question}
                                    </h1>
                                    <p className={activeQuestion === index ? 'active' : ''}>
                                        {item.answer}
                                    </p>
                                </div>
                            ))}
                        </div>

                        <div className='more-questions'>
                            <h1>Our team is always ready to assist you with any queries</h1>
                            <a href={`${whatsapp}`} target='_blank' aria-label='contact us'>
                                Get In Touch
                            </a>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default Faqs;
