import './App.css';

import { Routes, Route, useLocation } from "react-router-dom";
import React, { useState, useRef,useEffect } from "react";



import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.min.js';

import AOS from 'aos';
import 'aos/dist/aos.css';

import Navbar from './components/Navbar';
import Landing from './components/Landing';
import About from './components/About';
import WhyChooseUs from './components/WhyChooseUs';
import OurServices from './components/OurServices';
import Stats from './components/Stats';
import Faqs from './components/Faqs';
import Contact from './components/Contact';
import Footer from './components/Footer';


function App() {



return (
    <div className="App">

            <Routes>

            
            <Route path="/" element={
                    <>
                        <Navbar />
                        <Landing />
                        <About />
                        <WhyChooseUs />
                        <OurServices />
                        <Stats />
                        <Faqs />
                        <Contact />
                        <Footer />
                    </>
                } />

        </Routes>



        
    </div>
);
}

export default App;
