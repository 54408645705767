import './css/contact.css';

function Contact(){

    const phonenumber = "+97143312236";
    const whatsapp = "https://wa.me/+971503210099";
    const email = "info@darakt.com";
    return(
        <>
            <section id="contact" className='contact'>
                <div className='container-fluid'>
                    <div className='row'>
                        <div className='col-lg-6 col-md-6 col-sm-12 col-12'>
                            <div className='contact-form'>
                                <h2> We’re here to help! </h2>
                                <h1> Our team is ready to assist you</h1>
                                <p>
                                You can reach us by filling out the contact form on our website. Simply provide your name, email, and message, and we’ll get back to you as soon as possible.


                                </p>

                            <form>
                                <div className='inputs'>
                                    <div className='input'>
                                        <input type='text' placeholder='First name' required  />
                                    </div>

                                    <div className='input'>
                                        <input type='text' placeholder='Last name' required  />
                                    </div>
                                </div>

                                <div className='inputs'>
                                    <div className='input'>
                                        <input type='email' placeholder='Your email' required  />
                                    </div>

                                    <div className='input'>
                                        <input type='tel' placeholder='Mobile number' required  />
                                    </div>
                                </div>


                                
                                <div className='inputs'>
                                    <div className='input'>
                                        <input type='text' placeholder='Address' required  />
                                    </div>
                                </div>


                                <div className='inputs'>
                                    <div className='input'>
                                        <textarea placeholder='Your Message' required >

                                        </textarea>
                                    </div>
                                </div>

                                <button type='submit'> Send Message </button>


                            </form>
                            </div>

                        </div>


                        <div className='col-lg-6 col-md-6 col-sm-12 col-12'>
                            <div className='contact-info'>
                            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d900.8175622603552!2d55.53533223104233!3d25.42922696091599!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3ef5f7cb9640e3d7%3A0xb6dfa59e9dc59bad!2sAl%20Baher%20Building%20Equipment%20Rental!5e0!3m2!1sar!2seg!4v1717327189828!5m2!1sar!2seg" width="100%" height="350"  allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>

                                <h1> We’re Here for You </h1>
                                <p> 
                                At Dar Alkhobra Technology, we value our customers and strive to provide exceptional service. Don’t hesitate to reach out with any questions or concerns. Thank you for choosing us for your electronic needs!
                                </p>

                                <div className='info'>
                                <i class="las la-map-marker-alt"></i>


                                    <div>
                                        <h2> Location</h2>
                                        <p>
                                        Office no. 367-203, Al Baher Building, Abu Bakr Alseddeek street D78, Al Khabaisi, Dubai
                                        </p>
                                    </div>
                                </div>

                                <div className='info'>


                                <i class="las la-envelope"></i>
                                    <div>
                                        <h2> Email</h2>
                                        <p>
                                        {email}
                                        </p>
                                    </div>
                                </div>


                                
                                <div className='info'>
                                <i class="las la-headset"></i>

                                    <div>
                                        <h2> Give Us A Call</h2>
                                        <p>
                                        +971 4 331 2236
                                        </p>
                                    </div>
                                </div>
                                

                            </div>
                        </div>


                    </div>
                </div>
            </section>
        </>
    )
}

export default Contact;